var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.socialStatuse.socialStatuseImagePath,
            _vm.socialStatuse.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.socialStatuse.socialStatuseImageIsDefault &&
          _vm.checkPrivilege(_vm.hasSocialStatuseDeleteImage())},on:{"changeValue":function($event){_vm.socialStatuse.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.socialStatuse.fullCode,"title":_vm.$t('SocialStatuses.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.socialStatuse.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-socialStatuseNameAr"),"errors":_vm.errors_socialStatuseNameAr,"value":_vm.socialStatuse.socialStatuseNameAr,"title":_vm.$t('SocialStatuses.nameAr'),"imgName":'socialStatuses.svg'},on:{"changeValue":function($event){_vm.socialStatuse.socialStatuseNameAr = $event;
            _vm.$v.socialStatuse.socialStatuseNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-socialStatuseNameEn"),"errors":_vm.errors_socialStatuseNameEn,"value":_vm.socialStatuse.socialStatuseNameEn,"title":_vm.$t('SocialStatuses.nameEn'),"imgName":'socialStatuses.svg'},on:{"changeValue":function($event){_vm.socialStatuse.socialStatuseNameEn = $event;
            _vm.$v.socialStatuse.socialStatuseNameEn.$touch();}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-socialStatuseNotes"),"value":_vm.socialStatuse.socialStatuseNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.socialStatuse.socialStatuseNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }